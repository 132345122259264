import React from "react";
import {
  ContentWrapper,
  ConveyorBeltImg,
  DesktopContent,
  HeaderText,
  LeftContentWrapper,
  LeftSide,
  MainContainer,
  MobileButtonContainer,
  MobileContent,
  MobileGifContainer,
  MobileMottoContainer,
  MottoContainer,
  RightSide,
} from "./styles";
import Google from "../../assets/images/Google.png";
import Apple from "../../assets/images/Apple.png";
import EmailSignUp from "./EmailSignUp";
import LandingPageGraphic from "./assets/LandingPageGraphic.png";

export default function LandingPage() {
  return (
    <MainContainer>
      {/* Desktop View */}
      <DesktopContent>
        <ContentWrapper>
          <LeftSide>
            <LeftContentWrapper>
              <MottoContainer>
                <HeaderText>Connecting the world</HeaderText>
                <HeaderText>to building information</HeaderText>
                <EmailSignUp />
              </MottoContainer>
            </LeftContentWrapper>
          </LeftSide>

          <RightSide>
            <ConveyorBeltImg
              src={LandingPageGraphic}
              alt="Landing Page Graphic"
            />
          </RightSide>
        </ContentWrapper>
      </DesktopContent>

      {/* Mobile View */}
      <MobileContent>
        <MobileMottoContainer>
          <HeaderText>Connecting the World</HeaderText>
          <HeaderText>to Building Information</HeaderText>
        </MobileMottoContainer>

        <MobileGifContainer>
          <ConveyorBeltImg
            src={LandingPageGraphic}
            alt="Landing Page Graphic"
          />
        </MobileGifContainer>
        <EmailSignUp />

        <MobileButtonContainer>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/robotic-imaging/id1546776636"
          >
            <img src={Apple} alt="download on app store" />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.roboticimaging&pcampaignid=web_share"
          >
            <img src={Google} alt="download on play store" />
          </a>
        </MobileButtonContainer>
      </MobileContent>
    </MainContainer>
  );
}
